import React, { useState, useContext } from "react";
import { Tabs, Spin, Typography, notification, Timeline } from "antd";
import TimeAgo from "react-timeago";
import CopyIconToClipboard from "./CopyIconToClipboard";
import DocumentTable from "./DocumentTable";
import TaskSteps from "./TaskSteps";
import "./OutputModal.css";
import { useTheme } from "../context/ThemeContext";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";
import PDFViewer from "./PDFViewer";
import { Table } from "antd";

const { Title } = Typography;
const TaskDetails = (props) => {
  const { isDarkMode } = useTheme();
  const [downloadingItem, setDownloadingItem] = useState(-1);
  const [visibleItemId, setVisibleItemId] = useState(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const activeKey = props.activeKey;
  const setActiveKey = props.setActiveKey;
  const faxNumbers = props.faxNumbers || null;
  const selectedData = props.selectedData;
  const role = props.role;
  const unsavedChanges = props.unsavedChanges;
  const setHasUnsavedChanges = props.setHasUnsavedChanges;
  const removeUnsavedChange = props.removeUnsavedChange;
  const refreshResubmittedTask = props.refreshResubmittedTask;
  const setOpenedPdf = props.setOpenedPdf || (() => {});
  const config = useContext(ConfigContext);
  const pageCategories = config.page_categories;

  const generatedLetterBackgroundStyle = {
    backgroundColor: isDarkMode ? "#1f1f1f" : "#e3e3e3",
  };

  const handleTabChange = (key) => {
    setActiveKey(key); // Update the active tab key when a tab is clicked
  };

  const handleViewClick = async (id) => {
    if (downloadingItem === id) {
      // View for this item is currently ongoing. Ignore further clicks until we are done.
      return;
    }
    if (visibleItemId === id) {
      setVisibleItemId(null);
      setOpenedPdf(false);
      return;
    }

    setDownloadingItem(id);

    AuthenticatedApi.get(`/api/task_metadata/${id}/download`)
      .then((response) => {
        console.log("Fetching URL for view: ", id, " is ", response.data.url);
        setVisibleItemId(id);
        setOpenedPdf(true);
        setSelectedFileUrl(response.data.url);
        setDownloadingItem(-1);
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
        setDownloadingItem(-1);
        setVisibleItemId(null); // Optionally hide the viewer on error
      });
  };

  const handleMetadataFileDownload = async (event, id) => {
    event.preventDefault();

    if (downloadingItem === id) {
      // Download for this item is currently ongoing. Ignore further
      // clicks until we are done.
      return;
    }

    setDownloadingItem(id);

    AuthenticatedApi.get(`/api/task_metadata/${id}/download`)
      .then((response) => {
        console.log("Download for ", id, " is ", response.data.url);
        fetch(response.data.url)
          .then((response) => response.blob())
          .then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob);
            const tempLink = document.createElement("a");
            tempLink.href = blobUrl;
            tempLink.download = response.data.file_name;
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobUrl);
            setDownloadingItem(-1);
          });
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        setDownloadingItem(-1);
        notification.error({ message: errorMessage });
      });
  };

  const handleMetadataFileFax = async (event, id) => {
    event.preventDefault();
    AuthenticatedApi.get(`/api/task_metadata/${id}/send_as_fax`)
      .then(() => {
        notification.success({
          message: formatMessage(config.messages.task.fax_sent_success),
        });
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  const UploadedFile = ({
    data,
    downloadingItem,
    handleMetadataFileDownload,
    UploadStyle,
    customText,
    skipClass = false,
    showView = false,
  }) => {
    return (
      <div className={skipClass ? "" : "generated-letter"} style={UploadStyle}>
        <Title level={4}>{customText}</Title>
        <ol>
          {data.map((file) => (
            <li key={file.id}>
              {downloadingItem === file.id && <Spin />}
              <a
                href={`record/${file.id}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => handleMetadataFileDownload(e, file.id)}
              >
                {file.name}
              </a>
              {showView && (
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => handleViewClick(file.id)}
                >
                  {visibleItemId === file.id ? "Hide" : "View"}
                </button>
              )}
              {showView && visibleItemId === file.id && selectedFileUrl && (
                <PDFViewer
                  fileUrl={selectedFileUrl}
                  pageCategories={pageCategories}
                  fileName={file.name}
                  pagesResults={selectedData.pagesResults}
                  taskId={selectedData.selectedRow.id}
                />
              )}
            </li>
          ))}
        </ol>
      </div>
    );
  };

  const fileData = selectedData.additionalDataInput.filter(
    (item) => item.type_ === "file"
  );

  const relevant_details = selectedData.additionalDataInput.filter(
    (item) => item.tag === "relevant-details" || item.tag === "issues-input"
  );

  const nonFileOrRelevantDetails = selectedData.additionalDataInput.filter(
    (item) =>
      item.type_ !== "file" &&
      item.tag !== "relevant-details" &&
      item.tag !== "issues-input"
  );

  const HcpcsTable = ({ selectedData, index }) => {
    return (
      <>
        {selectedData.metadata &&
          selectedData.selectedRow &&
          (selectedData.selectedRow.task_type.includes("pwc_") ||
          selectedData.selectedRow.task_type.includes("mwc_")) &&
          selectedData.metadata.hcpcs_verdict &&
          Object.keys(selectedData.metadata.hcpcs_verdict).length > 0 &&
          index === 0 && (
            <Table
              style={{
                width: "100%",
                margin: "10px auto",
                fontSize: "12px",
              }}
              columns={[
                {
                  title: "HCPCS Code",
                  dataIndex: "hcpcs_code",
                  key: "hcpcs_code",
                  onHeaderCell: () => ({
                    style: { padding: "4px" },
                  }),
                },
                {
                  title: "Verdict",
                  dataIndex: "verdict",
                  key: "verdict",
                  render: (verdict) => (
                    <div
                      style={{
                        backgroundColor:
                          verdict === "met"
                            ? "#C7F1C3"
                            : verdict === "unmet"
                              ? "#F1C3C3"
                              : "#F1E9C3",
                        padding: "4px",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    >
                      {verdict.charAt(0).toUpperCase() + verdict.slice(1)}
                    </div>
                  ),
                },
              ]}
              dataSource={Object.entries(
                selectedData.metadata.hcpcs_verdict
              ).map(([hcpcs_code, hcpcsEntry]) => ({
                hcpcs_code,
                verdict: hcpcsEntry.verdict,
              }))}
              pagination={false}
              bordered
              rowKey="hcpcs_code"
              rowClassName={() => "small-row"}
            />
          )}
      </>
    );
  };

  return (
    <>
      {selectedData && selectedData.selectedRow && (
        <Tabs
          activeKey={activeKey}
          onChange={handleTabChange}
          items={[
            {
              key: "3",
              label: "Overview",
              children: (
                <div style={{ width: "100% !important" }}>
                  {fileData.length > 0 && (
                    <UploadedFile
                      data={fileData}
                      downloadingItem={downloadingItem}
                      handleMetadataFileDownload={handleMetadataFileDownload}
                      UploadStyle={{}}
                      customText="Uploaded Files"
                      skipClass={true}
                      showView={true}
                    />
                  )}

                  <TaskSteps
                    key={selectedData.selectedRow.id}
                    stepsStatus={selectedData.selectedRow}
                    taskId={selectedData.selectedRow.id}
                    refreshResubmittedTask={refreshResubmittedTask}
                    metadata={selectedData.metadata}
                    fileData={fileData}
                    structuredData={selectedData.structuredData}
                  />
                </div>
              ),
            },
            {
              key: "2",
              label: "Input",
              children: (
                <div>
                  {fileData.length > 0 && (
                    <UploadedFile
                      data={fileData}
                      downloadingItem={downloadingItem}
                      handleMetadataFileDownload={handleMetadataFileDownload}
                      UploadStyle={{}}
                      customText="Uploaded Files"
                      skipClass={true}
                      showView={true}
                    />
                  )}
                  {relevant_details.length > 0 && (
                    <div style={generatedLetterBackgroundStyle}>
                      <div style={{ padding: "20px", paddingBottom: "0px" }}>
                        <Title level={4}>Relevant Details</Title>
                      </div>
                      {relevant_details.map((data) => (
                        <CopyIconToClipboard
                          key={`${selectedData.selectedRow.id}-${selectedData.selectedRow.task_type}-input-${data.id}`}
                          fieldType={data.type_}
                          details={
                            data.type_ === "json"
                              ? JSON.stringify(data.data)
                              : data.data
                          }
                          unsavedChanges={unsavedChanges}
                          setHasUnsavedChanges={setHasUnsavedChanges}
                          removeUnsavedChange={removeUnsavedChange}
                          isEditable={false}
                        />
                      ))}
                    </div>
                  )}
                  {nonFileOrRelevantDetails.length > 0 &&
                    nonFileOrRelevantDetails.map((data, index) => (
                      <React.Fragment key={index}>
                        {data.type_ === "file" ? (
                          <></>
                        ) : (
                          <CopyIconToClipboard
                            key={`${selectedData.selectedRow.id}-${selectedData.selectedRow.task_type}-input-${data.id}`}
                            title={data.name}
                            fieldType={data.type_}
                            details={
                              data.type_ === "json"
                                ? JSON.stringify(data.data)
                                : data.data
                            }
                            unsavedChanges={unsavedChanges}
                            setHasUnsavedChanges={setHasUnsavedChanges}
                            removeUnsavedChange={removeUnsavedChange}
                            isEditable={false}
                          />
                        )}
                      </React.Fragment>
                    ))}
                </div>
              ),
            },
            role &&
              role === "superadmin" && {
                key: "4",
                label: "Intermediate",
                children: (
                  <>
                    {selectedData && selectedData.uuid && (
                      <Title level={4}>Task ID: {selectedData.uuid}</Title>
                    )}
                    <Tabs
                      type="card"
                      items={[
                        selectedData?.llm_logs && {
                          key: "llm_logs",
                          label: "LLM Logs",
                          children: (
                            <div
                              className="generated-letter"
                              style={generatedLetterBackgroundStyle}
                            >
                              <CopyIconToClipboard
                                key={`${selectedData.selectedRow.id}-${selectedData.selectedRow.task_type}-llm_logs`}
                                title="LLM Logs"
                                fieldType="json"
                                details={JSON.stringify(
                                  selectedData.llm_logs,
                                  null,
                                  2
                                )}
                                unsavedChanges={unsavedChanges}
                                setHasUnsavedChanges={setHasUnsavedChanges}
                                removeUnsavedChange={removeUnsavedChange}
                                isEditable={false}
                              />
                            </div>
                          ),
                        },
                        selectedData?.metadata?.eligibility_check_responses && {
                          key: "eligibility_check_responses",
                          label: "Eligibility Check",
                          children: (
                            <div
                              className="generated-letter"
                              style={generatedLetterBackgroundStyle}
                            >
                              <CopyIconToClipboard
                                key={`${selectedData.selectedRow.id}-${selectedData.selectedRow.task_type}-eligibility_check_responses`}
                                title="Eligiblity Check"
                                fieldType="json"
                                details={JSON.stringify(
                                  selectedData.metadata
                                    .eligibility_check_responses,
                                  null,
                                  2
                                )}
                                unsavedChanges={unsavedChanges}
                                setHasUnsavedChanges={setHasUnsavedChanges}
                                removeUnsavedChange={removeUnsavedChange}
                                isEditable={false}
                              />
                            </div>
                          ),
                        },
                        ...(selectedData?.structuredData
                          ? selectedData.structuredData.map((data, index) => {
                              return {
                                key: index.toString(),
                                label: data.name,
                                children:
                                  data.type_ === "file" ? (
                                    <div
                                      className="generated-letter"
                                      style={generatedLetterBackgroundStyle}
                                    >
                                      <Title level={3}>
                                        File Uploaded By User{" "}
                                        {downloadingItem === data.id && (
                                          <Spin />
                                        )}{" "}
                                      </Title>
                                      <a
                                        href={`record/${data.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={(e) =>
                                          handleMetadataFileDownload(e, data.id)
                                        }
                                      >
                                        {data.name}
                                      </a>
                                    </div>
                                  ) : (
                                    <CopyIconToClipboard
                                      key={`${selectedData.selectedRow.id}-${selectedData.selectedRow.task_type}-input-${data.id}-${selectedData?.documentCategory?.data}`}
                                      title={data.name}
                                      fieldType={data.type_}
                                      details={
                                        data.type_ === "json"
                                          ? JSON.stringify(data.data, null, 2)
                                          : data.data
                                      }
                                      unsavedChanges={unsavedChanges}
                                      setHasUnsavedChanges={
                                        setHasUnsavedChanges
                                      }
                                      removeUnsavedChange={removeUnsavedChange}
                                      isEditable={false}
                                    />
                                  ),
                              };
                            })
                          : []),
                      ].filter(Boolean)}
                    />
                  </>
                ),
              },
            {
              key: "1",
              label: "Output",
              children: (
                <div>
                  {/* Existing Output content */}
                  {selectedData.additionalData &&
                    selectedData.additionalData.map((data, index) => (
                      <React.Fragment key={index}>
                        {data.type_ === "file" ? (
                          <div
                            className="generated-letter"
                            style={generatedLetterBackgroundStyle}
                          >
                            <Title level={3}>
                              {data.name}{" "}
                              {downloadingItem === data.id && <Spin />}
                            </Title>

                            {/* File download link */}
                            <a
                              href={`record/${data.id}`}
                              target="_blank"
                              rel="noreferrer"
                              onClick={(e) =>
                                handleMetadataFileDownload(e, data.id)
                              }
                            >
                              {data.name}
                            </a>

                            {/* Fax option */}
                            {faxNumbers &&
                              faxNumbers.length > 0 &&
                              data.file_url &&
                              !data.file_url.includes(".mp3") && (
                                <a
                                  href={`record/${data.id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    backgroundColor: "#1890ff",
                                    marginLeft: "10px",
                                    padding: "5px",
                                  }}
                                  onClick={(e) =>
                                    handleMetadataFileFax(e, data.id)
                                  }
                                >
                                  Send as Fax
                                </a>
                              )}
                          </div>
                        ) : (
                          <>
                            <CopyIconToClipboard
                              key={`${selectedData.selectedRow.task_id}-${selectedData.selectedRow.task_type}-assessment-${data.id}`}
                              title={data.name}
                              taskId={selectedData.selectedRow.id}
                              fieldType={data.type_}
                              details={
                                data.type_ === "json"
                                  ? JSON.stringify(data.data, null, 2)
                                  : data.data
                              }
                              data_id={data.id}
                              isEditable={true}
                              unsavedChanges={unsavedChanges}
                              setHasUnsavedChanges={setHasUnsavedChanges}
                              removeUnsavedChange={removeUnsavedChange}
                              tableElement={
                                <HcpcsTable
                                  selectedData={selectedData}
                                  index={index}
                                />
                              }
                            />
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  {/* Documents section */}
                  {selectedData.metadata &&
                    selectedData.metadata.identified_documents &&
                    selectedData.metadata.identified_documents.length > 0 &&
                    selectedData.selectedRow.status === "Completed" && (
                      <div
                        className="generated-letter"
                        style={generatedLetterBackgroundStyle}
                      >
                        <Title level={3}>Documents</Title>
                        <DocumentTable
                          dataSource={
                            selectedData.metadata.identified_documents
                          }
                          fileData={fileData}
                        />
                      </div>
                    )}
                  {/* Event Timelines */}
                  {selectedData.event_logs && (
                    <div
                      className="generated-letter"
                      style={generatedLetterBackgroundStyle}
                    >
                      <Title level={3}>Event Timelines</Title>
                      <Timeline>
                        {selectedData.event_logs.map((log, index) => (
                          <Timeline.Item key={index}>
                            {log.user_name} {log.event} on{" "}
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              timeZoneName: "short",
                            }).format(new Date(log.timestamp))}{" "}
                            (
                            <span style={{ fontSize: "smaller" }}>
                              <TimeAgo date={log.timestamp} />
                            </span>
                            )
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </div>
                  )}
                </div>
              ),
            },
          ].filter(Boolean)}
        />
      )}
    </>
  );
};

export default TaskDetails;
